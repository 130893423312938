import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import theme from '../theme';
import MUILink from '@mui/material/Link';

import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import coffeeShop from '../img/CoffeeShop-AS_206899395.jpg';
import { upperCase } from 'lodash';

const SectionContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	zIndex: 1,
	position: 'relative',
	height: '100%',
	overflow: 'hidden',
});

// eslint-disable-next-line
export const GetInTouchTemplate = ({ title, content, contentComponent }) => {
	const PageContent = contentComponent || Content;

	return (
		<SectionContent py={0} px={6} m={0}>
			<Box
				sx={{
					// maxHeight: '400px',
					position: 'absolute',
					width: '100%',
					height: '100%',
					top: '0px',
					left: '0px',
					backgroundColor: '#fff',
					img: {
						height: { xs: '100%' },
						margin: 0,
						maxWidth: 'none',
						padding: 0,
						position: 'absolute',
						right: { xs: 0, md: '-70px' },
						top: { xs: 0 },
						width: { xs: '100%', md: '60%' },
						objectFit: 'cover',
						opacity: { xs: '0.2', md: 1 },
					},
				}}
			>
				<Box
					sx={{
						background:
							'linear-gradient(to left, rgba(255,255,255,0) 30%,rgba(255,255,255,1) 49%,rgba(255,255,255,1) 100%)',
						/*background: transparent url("img/gradient.png") bottom right repeat-y;*/
						bottom: 0,
						height: '100%',
						left: 170,
						position: 'absolute',
						width: '80%',
						MozBackgroundSize: '100% 100%',
						OBackgroundSize: '100% 100%',
						WebkitBackgroundSize: '100% 100%',
						backgroundSize: '100% 100%',
						zIndex: 1,
						display: { xs: 'none', md: 'block' },
					}}
				/>
				<img src={coffeeShop} alt='ALEIGN Instructions' />
			</Box>
			<Grid container justify='space-between' sx={{ zIndex: '1' }}>
				{/* <Grid item xs={0} md={7}></Grid> */}
				<Grid
					item
					xs={12}
					md={7}
					p={5}
					sx={{ textAlign: 'center', color: theme.palette.background.default }}
				>
					<Typography
						variant='body1'
						sx={{ fontWeight: 'inherit', pt: 4, b: { fontStyle: 'italic' } }}
					>
						Hey, How ya doing?
					</Typography>

					<Typography
						variant='h5'
						color='primary'
						sx={{ m: 0, pt: 2, fontSize: '1.3rem' }}
					>
						Let's get you through to the right place.
					</Typography>
					<Typography variant='body1' sx={{ mb: 0, pt: 1 }}>
						If you've already joined the ALEiGN-ment (&amp; have your sign in
						credentials)
						<br />
						<b>follow the 'SIGN IN' button below</b>
					</Typography>
					<Typography sx={{ fontSize: '.8rem', textTransform: 'uppercase' }}>
						if you need help signing in...
						<br />
						<MUILink
							component='button'
							onClick={() => window.FreshworksWidget('open')}
							sx={{ lineHeight: '1.7' }}
						>
							leave us a message
						</MUILink>{' '}
						OR{' '}
						<MUILink
							// component='button'
							href='https://support.aleign.com'
							sx={{ lineHeight: '1.7' }}
						>
							visit our support portal
						</MUILink>
					</Typography>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent='center'
						alignItems='center'
						spacing={3}
						mt={2}
						pb={4}
					>
						<Button
							variant='aleign'
							component={Link}
							to='https://makebetterv2.aleign.com/'
							sx={{
								color: theme.palette.primary.main,
								'&:after, &:before': { background: theme.palette.primary.main },
								'&:hover,&:focus,&:active,&.active': {
									color: '#fff',
									backgroundColor: theme.palette.primary.main,
									borderColor: theme.palette.primary.main,
									mx: 4,
								},
							}}
						>
							Sign In
						</Button>
					</Stack>
					<Typography
						variant='h3'
						mt={4}
						sx={{ fontWeight: 700, textTransform: 'none' }}
						color='primary'
					>
						If you're new here,
					</Typography>
					<Typography
						variant='h2'
						color='primary'
						sx={{
							fontSize: '2.3rem',
							fontWeight: 'bold',
							pb: 2,
						}}
					>
						Welcome!
					</Typography>

					<Typography>
						<b>At ALEiGN, we strive to MAKE BETTER</b>,<br /> and this means we do
						things a little differently... right from the start.
					</Typography>
					<Typography variant='h5' color='primary' py={1}>
						We don't want to add to your 'to-do list'
					</Typography>
					<Typography>
						We want to understand how we can <b>MAKE BETTER</b>
						<br />
						the problem you are looking to solve.
					</Typography>
					<Typography sx={{ fontWeight: 'bold' }} py={1}>
						That's why you're here right? To solve a problem!
					</Typography>
					<Typography variant='body1' sx={{ fontWeight: 'inherit', pb: 4 }}>
						We've found that <b>a call or a coffee</b> is the best way to get to know
						each other, <b>understand the problem at hand</b> and discover if we can
						indeed, <b>find a solution.</b>
					</Typography>
					<Typography variant='body1' sx={{ fontWeight: 'inherit', pb: 4 }}>
						So, like the song says,
						<br /> <i>"Leave your name and your number and we'll get back to you"</i>
					</Typography>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent='center'
						alignItems='center'
						spacing={3}
						mt={4}
						pb={4}
					>
						<Button
							variant='aleign'
							// component={Link}
							// to='#'
							onClick={() => window.FreshworksWidget('open')}
							sx={{
								color: theme.palette.primary.main,
								'&:after, &:before': { background: theme.palette.primary.main },
								'&:hover,&:focus,&:active,&.active': {
									color: '#fff',
									backgroundColor: theme.palette.primary.main,
									borderColor: theme.palette.primary.main,
								},
							}}
							rel='noopener noreferrer'
						>
							Let's start the conversation
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</SectionContent>
	);
};

GetInTouchTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
	contentComponent: PropTypes.func,
};

const GetInTouch = ({ data }) => {
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<GetInTouchTemplate
				contentComponent={HTMLContent}
				title={post.frontmatter.title}
				content={post.html}
			/>
		</Layout>
	);
};

GetInTouch.propTypes = {
	data: PropTypes.object.isRequired,
};

export default GetInTouch;

export const GetInTouchQuery = graphql`
	query GetInTouch($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			frontmatter {
				title
			}
		}
	}
`;
